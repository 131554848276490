<template lang="">
  <v-container id="body-container-card-fvl">
    <BreadCrumbs :items="itemsBreadCrumbs" />
    <LoaderLinear />
    <div id="fvl-container-card-table">
    <v-card elevation="0" class="pa-4">
      <div class="d-flex justify-center">
        <v-text-field
          class="mb-6 searcher-input-fvl elevation-1"
          v-model="search"
          label="Buscador de roles"
          append-icon="search"
          dense
          filled
          hide-details
        ></v-text-field>
      </div>
      <v-data-table
        :headers="dataTableheaders"
        :items="this.persons.typesPerson"
        :search="search"
      >
        <template v-slot:item.permissions="{ item }">
          <v-chip
            v-for="(elem, index) in item.permissions"
            :key="index"
            outlined
            small
            class="mr-1 mb-1 mt-1"
            color="primary"
          >            
            {{ elem.name }}
          </v-chip>
          <div v-if="item.id===3">El rol de super usuario posee todos los permisos sin restricciones.</div>
        </template>
        <template v-slot:item.actions="{ item }">
          <div>
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              v-if="
                (user.permissions.indexOf(permissionsAll.rol_delete) >= 0 ||
                  user.person.types_person.id === 3) && item.id!==3
              "
            >
              edit
            </v-icon>
            <v-icon
              small
              @click="deleteItem(item, 1)"
              v-if="
                (user.permissions.indexOf(permissionsAll.rol_delete) >= 0 ||
                  user.person.types_person.id === 3) && item.id!==1 && item.id!==2 && item.id!==3
              "
            >
              delete
            </v-icon>
          </div>
        </template>
      </v-data-table>
      <Overlay />
    </v-card>
    </div>
    <SnackBar />
    <ButtonAdd
      :link="linkAdd"
      v-if="
        user.permissions.indexOf(permissionsAll.rol_create) >= 0 ||
          user.person.types_person.id === 3
      "
    />
    <DialogConfirm @action="deleteRol" />
     <Overlay />
  </v-container>
</template>
<script>
import router from "../../router";
import {
  links,
  itemsBreadCrumbs,
  beginRequest,
  endRequest,
} from "../../helpers";
import { mapState } from "vuex";
import {
  LoaderLinear,
  SnackBar,
  Overlay,
  DialogConfirm,
  BreadCrumbs,
  ButtonAdd,
} from "../../components/_commons";
import permissions from "../../helpers/permissions";
export default {
  name: "CardRoles",
  components: {
    BreadCrumbs,
    ButtonAdd,
    LoaderLinear,
    DialogConfirm,
    SnackBar,
    Overlay,
  },
  data() {
    return {
      linkAdd: links.userRole.create,
      itemsBreadCrumbs: itemsBreadCrumbs.rolesList,
      search: "",
      dataTableheaders: [
        {
          text: "Descripción",
          align: "start",
          value: "description",
          class:"file-name-table",
          width:200,
        },
        {
          text: "Permisos",
          align: "start",
          value: "permissions",
          class:"file-name-table",
          width: 600,
        },
        { text: "Acciones", value: "actions", class:"file-name-table",sortable: false },
      ],
      dataTableItems: [],
      url: "",
      actionDialog: "",
      permissionsAll: permissions,
    };
  },
  computed: {
    ...mapState({
      persons: (state) => state.persons,
      ui: (state) => state.ui,
      user: (state) => state.user,
    }),
  },
  methods: {
    async editItem(item) {
      await this.$store.dispatch("removeRoleSelectedDataAction");
      router.push(`${links.userRole.update}/${item.id}`);
    },
    async deleteItem(item) {
      const msg = `¿Esta seguro que desea eliminar el rol "${item.description}"?`;
      await this.$store.dispatch("setIdItemDialogAction", item.id);
      await this.$store.dispatch("setMsgDialogConfirmAction", msg);
      await this.$store.dispatch("setDialogShowAction");
    },
    async deleteRol() {
      await beginRequest();
      await this.$store.dispatch("setDialogShowAction");
      const { status, snack } = await this.$store.dispatch(
        "deleteTypesPerson",
        this.ui.idItemDialog
      );
      await endRequest(snack, status);
    },
  },
  async created() {
    await beginRequest();
    const { status, snack } = await this.$store.dispatch(
      "setTypesPersonAction"
    );
    await endRequest(snack, status);
  },
};
</script>
